export enum MeteringPointTypes {
  НЕУСТАНОВЛЕН = -1, // Параметр не установлен
  САМОВОЛЬНОЕ = 0, // Самовольное подключение
  ПУСТУЮЩАЯ = 1, // Пустующая точка
  СКВАЖИНА = 2, // Скважина
  ПРОМЫВКА = 3, // Промывка
  ХВС = 4, // ХВС
  ХВСБОЙЛЕР = 5, // ХВС на ГВС (бойлер)
  ХВССЕЗОН = 6, // ХВС на ГВС (сезон)
  ГВС = 7, // ГВС
  КАНАЛИЗАЦИЯ = 8, // Канализация
  ВРЕМЯНКА = 9, // Времянка
  ВОДОМАТ = 10, // Водомат
  ЛИВНЕВКА = 11, // Ливневка
  ПРИВОЗНАЯ = 12, // Привозная
}
