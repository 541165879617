











































































import { Component, Emit, Mixins, Prop, Watch } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { mapGetters, mapState } from "vuex";
import XForm from "../SimpleForm.vue";
import XButton from "../SimpleButton.vue";
import { accountingPointPlanForm } from "@/assets/scripts/form-fields/main";
import { MonthsEn } from "@/assets/scripts/form-fields/accounting-point-plan-form";
import {AxiosResponse} from "axios";
import DocGetterMixin from "@/components/mixins/DocGetter.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import {monthlyPlanAcceptedExtensions, monthlyPlanAcceptedExtensionsHint} from "@/assets/scripts/file-inputs";

const { values, attrs, validations, errors } = accountingPointPlanForm;

@Component({
  components: { XButton },
  mixins: [validationMixin],
  computed: {
    ...errors,
    ...mapState({
      appEnvironment: "appEnvironment",
      accountingPoint: "accountingPoint",
    }),
    ...mapGetters({
      pointId: "accountingPoint/pointId",
      pointName: "accountingPoint/name",
      pointServices: "accountingPoint/pointServices",
      pointPlanValues: "accountingPoint/pointPlanValues",
      contractId: "contract/id",
    }),
  },
  validations,
})
class AccountingPointPlanForm extends Mixins(XForm, AppApiMixin, DocGetterMixin) {
  [x: string]: any;

  @Prop({ required: true }) readonly showSlider!: boolean;
  @Prop({ required: true }) readonly year!: number;
  @Prop({ required: true }) readonly serviceType!: string;
  @Prop({ required: true }) readonly serviceTypeId!: number;

  values = values;
  defaultValues = values;
  allFieldAttrs = attrs;
  defaultFieldAttrs = attrs;
  loading = false;
  file: Array<File> | [] = [];
  fileError: Array<String> | [] = [];

  downloadButtonLoading = false; // флаг состояния кнопки скачивания шаблона на передачу объема

  fieldClassName = [
    "form__input",
    "form__input_text-align_right",
    "form__input_prefix_bold",
  ].join(" ");

  currentMonthIndex = new Date().getMonth() + 1;

  showSuccessChip = false;
  showErrorChip = false;
  error = "Ошибка";

  mounted() {
    this.setFormattedValues()
  }

  public changeFile(files: Array<File>) {
      this.file = files;
      if(this.file?.length === 0) {
        this.fileError = [];
        this.showErrorChip = false;
      }
  }

  get noFile() {
    return (this.file?.length || 0) <= 0;
  }

  get maxSize() {
    const { appEnvironment } = this;
    this.fileError = [];

    if(this.file?.length === 0)
      return false;

    if((this.file[0].size / (1024 * 1024)) > appEnvironment.constants["MONTHLY_PLAN_AVAILABLE_BYTES"]) {
      this.fileError = ["Превышен максимальный размер файла"];
      this.error = "Превышен максимальный размер файла";
      this.showErrorChip = true;
      return true;
    }

    return false;
  }

  public get inputDisabled(): boolean {
    const { appEnvironment } = this;

    return !(appEnvironment.constants && appEnvironment.constants["ЛКЮЛДОГНАГР"]);
  }

  public get isCurrentYear() {
    return this.year === new Date().getFullYear();
  }

  public get sortedValues() {
    let sortedValues: any = {};
    for (let i in this.pointPlanValues) {
      sortedValues = {
        ...sortedValues,
        [this.pointPlanValues[i]["год"]]: {
          ...sortedValues[this.pointPlanValues[i]["год"]],
          [this.pointPlanValues[i]["услуга"]]: this.pointPlanValues[i],
        },
      };
    }

    return sortedValues;
  }

  getMonthlyPlanAcceptedExtensions() { return monthlyPlanAcceptedExtensions() }
  getMonthlyPlanAcceptedExtensionsHint() { return monthlyPlanAcceptedExtensionsHint() }

  public downloadFile(): void {
    const { downloadMonthlyPlanTemplateDocument, downloadDocument } = this;

    this.downloadButtonLoading = true;

    downloadMonthlyPlanTemplateDocument()
      .then((response: AxiosResponse) => {
        downloadDocument(response);
      })
      .catch((err: any) => console.log(err))
      .finally(() => {
        this.downloadButtonLoading = false;
      });
  }

  public setFormattedValues() {
    if (
      this.sortedValues[this.year] &&
      this.sortedValues[this.year][this.serviceTypeId]
    ) {
      const chosenValues = this.sortedValues[this.year][this.serviceTypeId];
      if (chosenValues && Object.keys(chosenValues).length) {
        const monthKeys = [];

        for (let key in chosenValues) {
          if (key.match(/м\d\d/)) {
            monthKeys.push(key);
          }
        }

        //@ts-ignore
        const planValues = monthKeys.sort().map((key) => chosenValues[key]);
        planValues.forEach((value, i) => {
          const roundedValue = parseFloat(value.toFixed(6))
          this.values = { ...this.values, [MonthsEn[i + 1]]: roundedValue };
          this.allFieldAttrs = {
            ...this.allFieldAttrs,
            [MonthsEn[i + 1]]: {
              ...this.allFieldAttrs[MonthsEn[i + 1]],
              default: roundedValue,
            },
          };
        });
      }
    } else {
      const defaultValues: { [x: string]: number } = {};
      for (let month in MonthsEn) {
        defaultValues[month] = 0;
      }

      this.allFieldAttrs = this.defaultFieldAttrs;
      this.resetValuesToDefault();
    }
  }

  @Emit("")
  public emitCloseSliderEvt() {
    this.$emit("close-slider");
    this.resetValuesToDefault();
  }

  @Watch("showSlider")
  public showSliderChanged() {
    this.resetValuesToDefault();
  }

  @Watch("year")
  public yearChanged() {
    this.setValues();
  }

  @Watch("serviceType")
  public serviceTypeChanged() {
    this.setValues();
  }

  @Watch("pointPlanValues")
  public setValues() {
    this.setFormattedValues();
  }

  private resetValuesToDefault() {
    const fieldNames = Object.keys(this.values);
    const values: { [x: string]: string } = {};

    this.values = fieldNames.reduce((acc, fieldName) => {
      const defaultValue = this.allFieldAttrs[fieldName].default;

      return { ...acc, [fieldName]: defaultValue };
    }, values);

    this.file = [];
    this.values['uploadPattern'] = this.file;
    this.showSuccessChip = false;
    this.showErrorChip = false;
  }

  public async submitForm() {
    const formData = new FormData();
    const data: { [x: string]: number } = {};

    for (let key in this.values) {
      if (key === 'uploadPattern') {
        formData.append(key, this.file[0]);
      } else {
        //@ts-ignore
        const monthNum = MonthsEn[key];
        const monthKey = monthNum > 9 ? `м${monthNum}` : `м0${monthNum}`;
        data[monthKey] = +this.values[key];
        if(+this.values[key])
          formData.append('value', this.values[key]);
      }
    }

    this.loading = true;
    formData.append('m', this.currentMonthIndex.toString());
    formData.append('meteringPointId', this.pointId.toString());
    formData.append('service', this.serviceTypeId.toString());
    formData.append('year', this.year.toString());
    formData.append('contractId', this.contractId.toString());
    formData.append('pointType', this.accountingPoint.params.filter((item) => item['название'] === 'ВИДПОМЕЩ')[0].значение);

    try {
      const data = await this.sendPlanValues(formData);

      if (data && data.result && (data.appealId) != 0) {
        await this.fetchPlanValues(this.pointId);
        this.showSuccessChip = true;
      }
    } catch (e) {
      console.log(e);
      this.error = e.data;
      this.showErrorChip = true;
    }
    this.loading = false
  }
}

export default AccountingPointPlanForm;
