import {store} from "@/store/store";

// регистрация в лк
function agreementAcceptedExtensions(): string {
  return store.state.config.config["REGISTRATION_AVAILABLE_FORMATS"]
      .split(',')
      .map(_ => '.' + _)
      .join(',');
}
function agreementAcceptedExtensionsHint(): string {
  return 'Доступны форматы: ' + store.state.config.config["REGISTRATION_AVAILABLE_FORMATS"] +
      ', максимальный размер ' + Math.round((store.state.config.config["REGISTRATION_AVAILABLE_BYTES"] / (1024 * 1024))).toString() + 'МБ';
}

// помесячный план
function monthlyPlanAcceptedExtensions(): string {
  return store.state.config.config["MONTHLY_PLAN_AVAILABLE_FORMATS"]
      .split(',')
      .map(_ => '.' + _)
      .join(',');
}
function monthlyPlanAcceptedExtensionsHint(): string {
  return 'Доступны форматы: ' + store.state.config.config["MONTHLY_PLAN_AVAILABLE_FORMATS"] +
      ', максимальный размер ' + Math.round((store.state.config.config["MONTHLY_PLAN_AVAILABLE_BYTES"] / (1024 * 1024))).toString() + 'МБ';
}

// обращения
function appealsAcceptedExtensions(): string {
  return store.state.config.config["APPEALS_AVAILABLE_FORMATS"]
      .split(',')
      .map(_ => '.' + _)
      .join(',');
}
function appealsAcceptedExtensionsHint(): string {
  return 'Доступны форматы: ' + store.state.config.config["APPEALS_AVAILABLE_FORMATS"] +
      ', максимальный размер ' + Math.round((store.state.config.config["APPEALS_AVAILABLE_BYTES"] / (1024 * 1024))).toString() + 'МБ';
}

export {
  agreementAcceptedExtensions, agreementAcceptedExtensionsHint,
  monthlyPlanAcceptedExtensions, monthlyPlanAcceptedExtensionsHint,
  appealsAcceptedExtensions, appealsAcceptedExtensionsHint,
};
